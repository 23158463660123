<template>
  <div class="LoginModal"> 
      <!-- modal para login -->
          <div id="Login" class="boxModal animated fadeIn"> 
                  <div class="contentModal animated fadeInUp">
                    <div class="btnCerrar" @click="cerrarModal()">X</div> 
                    <div class="row">
                        <div class="col-md-6 text-left flexCenter">    
                            <Lang/>                                                        
                        </div>
                        <div class="col-md-6 text-right"> 
                            <nav class="menuUser Logout">
                                <router-link to="#" @click="openModal('Register')" class="nameMenu">{{ $t("auth.register_button") }}</router-link>
                                <router-link to="#" @click="openModal('Login')" class="nameMenu">{{ $t("auth.sign_in_button") }}</router-link>
                            </nav>
                        </div>
                    </div>     
                    <div class="row">
                        <form @submit.prevent="Login">
                        <div class="col-md-12">
                            <img class="imgLoginLogo" src="../../assets/img/logos/logo-newl.png" alt="Logo iwim">
                            <p>{{$t("modal.registro.title")}}</p>
                            <p>{{ $t("auth.desc_login") }}</p>
                            <img class="imgLoginprofile" src="../../assets/img/icons/profile-icon-grande.png" alt="">
                            <input type="text" v-model="username" name="userLog" id="userLog" :placeholder='$t("forms.label.username")' required>
                            <input type="password" v-model="password" name="pwwLog" id="pwwLog" :placeholder='$t("forms.label.password")' required>
                        </div>    
                        <div class="col-md-12"><button class="btn Cancelar" @click="Login()">{{ $t("auth.sign_in_button") }}</button></div>    
                        <div class="col-md-12 flexCenter"><input type="checkbox" name="contect" id="contect" class="check"> <label for="contect">{{ $t("auth.Mantenerme_conectado") }} </label></div>   
                        <div class="col-md-12"><router-link to="#" @click="openModal('Olvido')" class="btnOlvidado">{{ $t("auth.forgot_password") }}</router-link></div>   
                        <div class="col-md-12"><img class="imgLoginLogoF" src="../../assets/img/logos/Logo-2-ESP-2-e1605491336772.png" alt=""></div> 
                        </form>
                    </div>            
                  </div>            
          </div>
      <!-- modal para login -->
          <!-- modal aviso -->
          <AlertModal id="aviso-login" class="boxModal animated fadeIn aviso"
            :tituloMsj='"Login"'
            :msj='msjAlert'
          />         
      <!-- modal aviso -->
  </div> 
</template>
<script>  
import Lang from '@/components/lang/Language' 
import { mapGetters, mapActions } from 'vuex'
import { Auth } from 'aws-amplify';

export default {
  name: 'LoginModal',
  components: {  
    Lang, 
  },  
  data: () => ({
    cargando:false,
    cont:0,
    username:'',
    password:'',
    msjAlert:""
  }),computed: {
    ...mapGetters(['currentUser'])
  }, 
  methods:{   
    ...mapActions(["getCurrentUser"]),
    openModal(id){ 
        this.cerrarModal()
         setTimeout(() => {          
            $('#'+id).show()   
            $('#'+id).removeClass('fadeOut').addClass('fadeIn')
            $('#'+id+' .contentModal').addClass('fadeInUp').removeClass('fadeOutDown')
        }, 1000);
    },
    cerrarModal(){ 
        $('.boxModal').removeClass('fadeIn').addClass('fadeOut')
        $('.contentModal').removeClass('fadeInUp').addClass('fadeOutDown')
        setTimeout(() => {
          $('.boxModal').removeAttr('style')
          $('.contentModal').removeClass('fadeOutDown')
        }, 1000);        
    },      
    async Login(){ 
        if (this.cargando) 
          return false; 
    
        if($('#userLog').val()!="" && $('#pwwLog').val() !=""){
            this.cargando=true; 
            $('#loaderChase').show()
            try {
                let _er = await Auth.signIn(this.username, this.password);
               // console.log('er', _er.challengeParam.userAttributes.email);
                //this.$store.commit("setCurrentUser", _er);
               // _er.challengeParam.userAttributes.email
               if(_er.attributes){
                await this.getCurrentUser(_er.attributes.email);
               }else if(_er.challengeParam.userAttributes)

                              /*console.log(this.currentUser);
                if(!this.currentUser.attributes.user){
                    this.msjAlert="Usuario ha sido inactivado. Debe registrarse de nuevo";
                    this.cerrarModal();  
                    this.openModal('aviso-login');
                    $('#loaderChase').hide();
                    Auth.signOut();
                    return;
                }*/
                $('#menuLogin').css({'display':'none'})
                $('#menuUser').css({'display':'flex'})
                this.cerrarModal();  
                $('#loaderChase').hide()
            } catch (error) {
             // alert (error);   
              if(error.message === 'User does not exist.'){
                this.msjAlert = this.$t('login.error.userNotExist')
              }else if(error.message === 'Incorrect username or password.'){
                this.msjAlert = this.$t('login.error.incorrectUserOrPassword')
              }else(
                this.msjAlert = error.message
              )
              
              this.openModal('aviso-login')
               $('#loaderChase').hide()
            }
            this.cargando=false; 
        }       
    }
  },
}
</script>