import { createI18n } from "vue-i18n";
import messages from "./es.json";

export const i18n = new createI18n({
  locale: "es", // set locale
  fallbackLocale: "es",
  messages: {
    es: messages,
  }, // set locale messages
});

const loadedLanguages = ["es"];

function setI18nLanguage(lang) {
  i18n.locale = lang;
  i18n.global.locale=lang;
  // axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector("html").setAttribute("lang", lang);
  document.querySelector("body").setAttribute("class", lang);
  return lang;
}

export function loadLanguageAsync(lang) {

  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language hasn't been loaded yet
  return import(
    /* webpackChunkName: "lang-[request]" */ `@/i18n/${lang}.json`
  ).then((messages) => {
    //i18n.setLocaleMessage(lang, messages.default);

    i18n.global.setLocaleMessage(lang, messages);
    loadedLanguages.push(lang);
    return setI18nLanguage(lang);
  });
} 