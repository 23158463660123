<template>
  <div class="ConfirmModal"> 
       <!-- modal aviso --> 
                  <div class="contentModal animated fadeInUp"> 
                      <div class="row"> 
                        
                          <div class="col-md-12"> 
                              <div class="titModal">{{tituloMsj}}</div>
                          </div>
                          <div class="col-md-12"> 
                              <p class="textModal" id="textAviso">{{msj}}</p>
                              <div class="boxBtnModal">
                                <button class="btnSelect Guardar" v-on:click="$emit('emit')">{{ $t("button.Acept_button") }}</button>
                                <button class="btnSelect Cancelar" @click="cerrarModal()">{{ $t("button.Cancel_button") }}</button>
                              </div>
                              
                          </div>
                      </div>                   
                  </div>          

      <!-- modal aviso -->
  </div> 
</template>
<script>  
  
export default {
  name: 'ConfirmModal',
  components: {      
  },  
  data: () => ({  
    cont:0
  }), 
  methods:{    
    cerrarModal(){ 
        $('.boxModal').removeClass('fadeIn').addClass('fadeOut')
        $('.contentModal').removeClass('fadeInUp').addClass('fadeOutDown')
        setTimeout(() => {
          $('.boxModal').removeAttr('style')
          $('.contentModal').removeClass('fadeOutDown')
        }, 1000);
        
    },  
  },
  props: { 
      msj: {
        type: Text,
        default: 'Confirm'
      }, 
      tituloMsj: {
        type: Text,
        default: 'Confirm'
      },  
    }, 
}
</script>