import { createRouter, createWebHistory } from 'vue-router' 
import { Auth } from 'aws-amplify';

function guardMyroute(to, from, next)
{
  Auth.currentAuthenticatedUser()
  .then(user => {
    next()
    
  })
  .catch(e => {
      console.log(e, user);
      next('/');
  });
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/mentoreo-jovenes',
    name: 'jovenes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MentoreoJovenes.vue')
  },
  {
    path: '/taller-de-mentoreo',
    name: 'taller',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TallerDeMentoreo.vue')
  },
  {
    path: '/mentoreo-adulto',
    name: 'adulto',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MentoreoAdulto.vue')
  },
  {
    path: '/mentorlink',
    name: 'mentorlink',
    //beforeEnter : guardMyroute,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Mentorlink.vue')
  },
  {
    path: '/mis-grupos',
    name: 'misgrupos',
    beforeEnter : guardMyroute,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MisGrupos.vue')
  },
  {
    path: '/migration',
    name: 'migration',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Migration.vue')
  },
  {
    path: '/Seguimiento/:id',
    name: 'Seguimiento',
    beforeEnter : guardMyroute,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Seguimiento.vue')
  },
  {
    path: '/crear-grupo',
    name: 'crear',
    beforeEnter : guardMyroute,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CrearGrupo.vue')
  },
  {
    path: '/perfil',
    name: 'perfil',
    beforeEnter : guardMyroute,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Perfil.vue')
  },
  {
    path: '/Reporte-iwin',
    name: 'ReporteIwin',
    beforeEnter : guardMyroute,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ReporteIwin.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
      }
    }
  },
})

export default router
