 
import { createApp } from 'vue' 
import App from './App.vue'
import router from './router'
import store from './store'
import icons from "v-svg-icons";
import {Amplify} from "aws-amplify";
import awsconfig from "@/aws-exports"; 
import { i18n, loadLanguageAsync } from "./i18n";
Amplify.configure(awsconfig);
const $ = require('jquery')
window.$ = $  
import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import AlertModal from '@/components/modal/Alert' 
import ConfirmModal from '@/components/modal/Confirm' 
import "primevue/resources/primevue.min.css"  
import "primeicons/primeicons.css"   
import "primevue/resources/themes/lara-light-indigo/theme.css";

// import "primevue/resources/themes/bootstrap4-light-blue/theme.css"
import "./assets/css/animate.min.css";
import "./assets/css/styles.css";
import "./assets/css/responsive.css"; 
import dateHelpers from '@/helpers/date-helpers'
import stringHelpers from '@/helpers/string-helpers'
import notificationHelpers from '@/helpers/notification-helpers'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

  router.beforeEach(async (to, from, next) => {
   
    const lang = localStorage.getItem("locale") || "es";
    await loadLanguageAsync(lang);
    next();
  });

const app = createApp(App)
.use(store)
.use(PrimeVue)
.use(router)
.use(i18n)
.use(notificationHelpers)
.use(dateHelpers)
.use(stringHelpers)
.use(Toast)
.component('DataTable',DataTable)
.component('AlertModal',AlertModal)
.component('ConfirmModal',ConfirmModal)
.component('Column',Column)
.component('InputText',InputText)
.component('icon',icons) 
.mount('#app') 
 
export default app
 
