import moment from 'moment'
// import * as sHelpers from '@/helpers/string-helpers'

const dateHelper = {
    install(app) {
        app.config.globalProperties.$numFormat = (key) => {
            return Number(key).toLocaleString();
        },
        app.config.globalProperties.$numFormatWithDollar = (key) => {
            return key ? '$' + Number(key).toLocaleString() : '-';
        }
        app.config.globalProperties.$format = (d, f = 'DD/MM/YYYY HH:mm') => d && moment(d).isValid() && moment(d).format(f)
        app.config.globalProperties.$dateIsPast = (d) => moment(d).isValid() && moment().diff(moment(d)) > 0
        app.config.globalProperties.$getMonths = (lang = 'en') => moment.localeData(lang).months().map(this.capitalize)
    }
}
export default dateHelper;