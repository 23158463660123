<template>
  <div class="RegisterModal"> 
      <!-- modal para registro -->
                <div id="Register" class="boxModal animated fadeIn"> 
                      <div class="contentModal animated fadeInUp">
                            <div class="btnCerrar" @click="cerrarModal()">X</div> 
                            <div class="row">
                                <div class="col-md-6 imgBgReg"></div>
                                <div class="col-md-12 col-xl-6">
                                    <div class="row">
                                        <div class="col-md-6 text-left flexCenter">                                             
                                          <Lang/>
                                        </div>
                                        <div class="col-md-6 text-right"> 
                                            <nav class="menuUser Logout">
                                                <router-link to="#" @click="openModal('Register')" class="nameMenu">{{ $t("auth.register_button") }} </router-link>
                                                <router-link to="#" @click="openModal('Login')" class="nameMenu">{{ $t("auth.sign_in_button") }} </router-link>
                                            </nav>
                                        </div>
                                    </div>     
                                    <div class="row pad20">
                                        <form @submit.prevent="register">
                                            <div class="col-md-12">
                                                <img class="imgLoginLogo" src="../../assets/img/logos/logo-newl.png" alt="logo iwim">
                                                <p>{{$t("modal.registro.title")}}</p>   
                                                <label for="">{{$t("forms.label.email")}}</label>
                                                <input type="text" v-model="email" name="user" id="user"  required>
                                                
                                                <label for="">{{$t("forms.label.name_lastname")}}</label>
                                                <input type="text" v-model="fullName" name="nombreApellido" id="nombreApellido"  required>
                                                <div class="row">
                                                    <div class="col-md-6">       
                                                        <label for="">{{$t("forms.label.country")}}</label>                                             
                                                        <select v-model="country" name="Iglesia" id="Iglesia" required> 
                                                            <option>Argentina</option>
                                                            <option>Brasil</option>
                                                            <option>Bolivia</option>
                                                            <option>Chile</option>
                                                            <option>Colombia</option>
                                                            <option>Ecuador</option>
                                                            <option>Perú</option>
                                                            <option>Paraguay</option>
                                                            <option>Uruguay</option>
                                                            <option>Venezuela</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-6">   
                                                        <label for="">{{$t('data.your_phone_number')}}</label>                                                 
                                                        <input v-model="phone" type="text" name="Telefono" id="Telefono"   required>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">  
                                                        <label for="">{{$t('forms.label.password')}} <span class="min8"> ({{$t('forms.label.min8')}})</span> </label>
                                                        <input type="password" v-model="password" name="pww" id="pww"  required>
                                                    </div>
                                                    <div class="col-md-6">  
                                                        <label for="">Re-{{$t('forms.label.password')}}</label>
                                                        <input type="password" v-model="password2"  name="pww2" id="pww2"  required>
                                                    </div>
                                                </div>
                                                <h3 class="Adicional">{{$t('forms.label.dat_adicional')}}</h3>
                                                <div class="row">
                                                    <div class="col-md-6">   
                                                        <label for="">{{$t('forms.label.church')}}</label>
                                                        <input v-model="church" type="text" name="iglesia" id="iglesia"  required>
                                                    </div>
                                                    <div class="col-md-6">   
                                                        <label for="">{{$t('forms.label.distrito')}}</label>                                                 
                                                        <select v-model="userLocationId" name="DistritoReg" id="DistritoReg" required> 
                                                            <option v-for="d of distritos" :key="d.id" :value="d.id">{{ d.distrite }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">                                          
                                                        <label for="">{{$t('forms.label.cargo_en_IWIN')}}</label>              
                                                        <select v-model="range" name="range" id="range" required> 
                                                            <option value="Coordinador Nacional de iWIN" data-select2-id="21">{{$t('forms.cargo_en_IWIN.Coordinador_Nacional_de_iWIN')}}</option>
                                                            <option value="Facilitador Distrital de iWIN" data-select2-id="22">{{$t('forms.cargo_en_IWIN.Facilitador_Distrital_de_iWIN')}}</option>
                                                            <option value="Superintendente de Distrito" data-select2-id="23">{{$t('forms.cargo_en_IWIN.Superintendente_de_Distrito')}}</option>
                                                            <option value="Presidente JNI Distrital" data-select2-id="24">{{$t('forms.cargo_en_IWIN.Presidente_JNI_Distrital')}}</option>
                                                            <option value="Coordinador Integral de ministerios" data-select2-id="25">{{$t('forms.cargo_en_IWIN.Coordinador_Integral_de_ministerios')}} </option>
                                                            <option value="Coordinador Estratégico de Área" data-select2-id="26">{{$t('forms.cargo_en_IWIN.Coordinador_Estratégico_de_Área')}}</option>
                                                            <option value="Coordinador Regional de Ministerios" data-select2-id="27">{{$t('forms.cargo_en_IWIN.Coordinador_Regional_de_Ministerios')}}</option>
                                                            <option value="Coordinador Distrital de Ministerios" data-select2-id="28">{{$t('forms.cargo_en_IWIN.Coordinador_Distrital_de_Ministerios')}}</option>
                                                            <option value="Coordinador Nacional de Ministerio" data-select2-id="29">{{$t('forms.cargo_en_IWIN.Coordinador_Nacional_de_Ministro')}}</option>
                                                            <option value="Coordinador Regional Integral de ministerios" data-select2-id="25">{{$t('forms.cargo_en_IWIN.Coordinador_Regional_Integral_de_ministerios')}}</option>
                                                           <option value="Director Regional" data-select2-id="30">{{$t('forms.cargo_en_IWIN.Director_Regional')}}</option>
                                                            <option value="Otros" data-select2-id="31">{{$t('forms.cargo_en_IWIN.Otros')}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-6">                            
                                                        <label for="">{{$t('forms.label.tipo_de_mentor')}}</label>                                                                                                  
                                                        <select v-model="mentorType" name="mentorType" id="mentorType" required> 
                                                            <option value="Facilitador">{{$t('forms.tipo_de_mentor.Facilitador')}}</option>
                                                            <option value="Mentoreado">{{$t('forms.tipo_de_mentor.Mentoreado')}}</option>
                                                            <option value="Facilitador Jóvenes">{{$t('forms.tipo_de_mentor.Facilitador_Jovenes')}}</option>
                                                            <option value="Mentoreado joven">{{$t('forms.tipo_de_mentor.Mentoreado_joven')}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                
                                            </div>                                             
                                            <router-link to="#" class="btnPoliticas">{{$t('forms.label.Mostrar_politica_de_privacidad')}}</router-link> 
                                            <div class="col-md-12 flexCenter"><input type="checkbox" name="politica" id="politica" class="check" required> <label for="politica" class="politicas">{{$t('forms.label.acep_priv')}}</label></div>   
                                            <div class="col-md-12"><button class="btn Cancelar">{{$t('auth.register_button')}}</button></div>   
                                           
                                        </form>
                                    </div>    
                                </div>
                            </div>        
                        </div>          
                </div>
      <!-- modal para registro-->
          <!-- modal aviso -->
                  <AlertModal id="aviso-registro" class="boxModal animated fadeIn aviso"
                    :tituloMsj='"Registro"'
                    :msj='msjAlert'
                  />         
      <!-- modal aviso -->
          <!-- modal aviso -->
                  <ConfirmInput id="confirm-registro"  class="boxModal animated fadeIn aviso"
                    :tituloMsj='"Confirmar registro"'
                    :msj='msjAlert' 
                    v-on:emit="confirEmail"
                  />         
      <!-- modal aviso -->
  </div> 
</template>
<script>   
import ConfirmInput from '@/components/modal/ConfirmInput' 
import { Auth } from 'aws-amplify';
import { API } from 'aws-amplify';
import { listLocations } from '@/graphql/queries';
import { listChurches } from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';
import Lang from '@/components/lang/Language'
export default {
  name: 'RegisterModal',
  components: {  
    Lang, 
    ConfirmInput
  },  
  data() {
        return {
            email: '',
            password: '',
            password2: '',
            distritos: [],
            country: '',
            range: '',
            phone: '',
            mentorType:'',
            fullName:'',
            userLocationId:'',
            church:'',
            msjAlert:'',
        };
  },
    methods:{      
    confirmAusi(){
        Auth.confirmSignUp('vcorrea@medianet.com.ve', '255334');
    },
    openModal(id){ 
        this.cerrarModal()
         setTimeout(() => {          
            $('#'+id).show()   
            $('#'+id).removeClass('fadeOut').addClass('fadeIn')
            $('#'+id+' .contentModal').addClass('fadeInUp').removeClass('fadeOutDown')
        }, 1000);
    },
    cerrarModal(){ 
        $('.boxModal').removeClass('fadeIn').addClass('fadeOut')
        $('.contentModal').removeClass('fadeInUp').addClass('fadeOutDown')
        setTimeout(() => {
          $('.boxModal').removeAttr('style')
          $('.contentModal').removeClass('fadeOutDown')
        }, 1000);
        
    },
     confirEmail: async function (code) {
        if(code){
            $('#loaderChase').show()
           console.log('code', code) 
           try {
            await Auth.confirmSignUp(this.email, code);
            this.cerrarModal();
            $('#loaderChase').hide()
            this.msjAlert=this.$t("auth.confirmed");
            this.openModal('aviso-registro') 
           } catch (error) {
                $('#loaderChase').hide()
                this.cerrarModal()
                this.msjAlert=error.message 
                this.openModal('confirm-registro') 
           }
        }
    },
    async register() {
        if(this.password2==this.password){
             $('#loaderChase').show()
            try {
                let filter = {
                    churchLocationId: {
                        eq: this.userLocationId
                    },
                    name: {
                        eq: this.church
                    }
                };
                let id ='';
                const c = await API.graphql({ query: listChurches, variables: { filter: filter}});
                //console.log(c);
                if (c.data.listChurches.items.length==0){
                    const cDetails = {
                        name: this.church,
                        churchLocationId: this.userLocationId,
                        address:''
                    };

                    const newC = await API.graphql({ query: mutations.createChurch, variables: {input: cDetails}});
                    //console.log(newC);
                    id=newC.data.createChurch.id;
                }else{
                    id = c.data.listChurches.items[0].id;
                }
                //console.log(id);
                let existia = false;
                try{
                   const user = await Auth.signUp({
                    username: this.email,
                    password: this.password
                   });               
                }catch (error) { 
                    console.log (error.message);
                    console.log (error.message =="An account with the given email already exists.");
                    if (error.message =="An account with the given email already exists."){
                        console.log("cuenta estaba borrada");
                        existia=true;
                    }else{
                        this.msjAlert=error.message;
                        this.openModal('aviso-registro');
                        $('#loaderChase').hide();
                        return;
                    }
                    // alert(error.message);
                }
                 const uDetails = {
                    range: this.range,
                    phone: this.phone,
                    mentorType: this.mentorType,
                    keyPhoto: '',
                    fullName: this.fullName,
                    userChurchId: id,
                    userLocationId: this.userLocationId,
                    username: this.email,
                };
                const newU = await API.graphql({ query: mutations.createUser, variables: {input: uDetails}});
                //console.log(newU);
                //console.log(user);
                this.msjAlert=this.$t("auth.registered");
                if (!existia)
                    this.openModal('confirm-registro');
                else{
                    this.msjAlert='Usuario registrado. Proceda a iniciar sesión';
                    this.openModal('aviso-registro');
                }
                $('#loaderChase').hide();
                // alert('Usuario registrado con éxito. Por favor Iniciar sesión');
            } catch (error) { 
                this.msjAlert=error.message 
                this.openModal('aviso-registro')
                $('#loaderChase').hide()
                // alert(error.message);
            }
        }else{
            
                this.msjAlert=this.$t("auth.password_error") 
                this.openModal('aviso-registro')
        }
    },  
  },
  watch: {
     async country(newValue, oldValue) {
        console.log('watch', newValue, oldValue)
        let filter = {
            country: {
                eq: this.country
            }
        };
        const distric = await API.graphql({ query: listLocations, variables: { filter: filter}});
        console.log(distric);
        this.distritos = distric.data.listLocations.items;
        var c = this.distritos.sort((x, y) => {
        return (
          (x.distrite > y.distrite ? 1 : -1)
        );
      });
     }
  }
}
</script>