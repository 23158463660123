<template>
  <div class="ChangePasswordModal"> 
       <!-- modal aviso --> 
                  <div class="contentModal animated fadeInUp"> 
                      <div class="row"> 
                        
                          <div class="col-md-12"> 
                              <div class="titModal">{{tituloMsj}}</div>
                          </div>
                          <div class="col-md-12">   
                              <p class="textModal" id="textAviso">{{msj}}</p>
                                <input type="text" class="inpCode"  v-model="code" placeholder="000000" maxlength="6"> 
                              
                                <label for="" class="textL">{{ $t("auth.new_password") }}</label> 
                                   <div class="boxPww">
                                    <icon class="iconTable iconlock" name="lock" color="#7c7c7c" size="20px"/>
                                    <input :type="typeNew" v-model="newP" name="pwwcp2" id="pwwcp2"   class="changeInp"   required>
                                      <icon class="iconTable icon" :name="iconNew" color="#7c7c7c" size="20px" @click="showNew()"/>
                                  </div>
                                <label for="" class="textL">Re-{{ $t("auth.new_password") }}</label> 
                                   <div class="boxPww">
                                      <icon class="iconTable iconlock" name="lock" color="#7c7c7c" size="20px"/>
                                    <input :type="typeNew" v-model="newPr" name="pwwcpr2" id="pwwcpr2" class="changeInp"     required>
                                      <icon class="iconTable icon" :name="iconNew" color="#7c7c7c" size="20px" @click="showNew()"/>
                                  </div>
                                  
                                <div class="msjerror">{{msjerr}}</div>
                              <div class="boxBtnModal">
                                <button class="btnSelect Guardar" v-on:click="$emit('emit',code,newP,newPr)">{{ $t("button.Confirm_button") }}</button>
                                <button class="btnSelect Cancelar" @click="cerrarModal()">{{ $t("button.Cancel_button") }}</button>
                              </div>
                              
                          </div>
                      </div>                   
                  </div>          

      <!-- modal aviso -->
  </div> 
</template>
<script>  
  
export default {
  name: 'ChangePasswordModal',
  components: {      
  },  
  data: () => ({  
    cont:0,
    code:"",    
    newP:"",
    newPr:"",
    iconNew:"eye",
    iconOld:"eye",
    typeNew:"password",
    typeOld:"password"
  }), 
  methods:{    
    cerrarModal(){ 
        $('.boxModal').removeClass('fadeIn').addClass('fadeOut')
        $('.contentModal').removeClass('fadeInUp').addClass('fadeOutDown')
        setTimeout(() => {
          $('.boxModal').removeAttr('style')
          $('.contentModal').removeClass('fadeOutDown')
        }, 1000);
        
    },  
    showNew(){
      if(this.iconNew=='eye'){
        this.iconNew='eye-slash'
        this.typeNew='text'
      }else{
        this.iconNew='eye'
        this.typeNew='password'
      }
    },  
    showOld(){
      if(this.iconOld=='eye'){
        this.iconOld='eye-slash'
        this.typeOld='text'
      }else{
        this.iconOld='eye'
        this.typeOld='password'
      }
    }
  },
  props: { 
      msj: {
        type: Text,
        default: 'Confirm'
      }, 
      msjerr:{
        type: Text,
        default: 'Confirm'
      },
      tituloMsj: {
        type: Text,
        default: 'Confirm'
      },  
    }, 
}
</script>