/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSessions = /* GraphQL */ `
  mutation CreateSessions(
    $input: CreateSessionsInput!
    $condition: ModelSessionsConditionInput
  ) {
    createSessions(input: $input, condition: $condition) {
      id
      date
      sessionNum
      group {
        id
        user {
          id
          username
          keyPhoto
          fullName
          phone
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          mentorType
          range
          Groups {
            nextToken
            __typename
          }
          comments {
            nextToken
            __typename
          }
          subComment {
            nextToken
            __typename
          }
          membersGroups {
            nextToken
            __typename
          }
          auth
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userLocationId
          userChurchId
          __typename
        }
        sections {
          items {
            id
            activeMembers
            initialDate
            state
            createdAt
            updatedAt
            groupSectionsId
            __typename
          }
          nextToken
          __typename
        }
        etareaGroup
        country
        distrite
        groupName
        description
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        initialDate
        finishDate
        members {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ministry
        language
        modality
        progress
        format
        subformat
        sessions {
          items {
            id
            date
            sessionNum
            createdAt
            updatedAt
            groupSessionsId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userGroupsId
        groupChurchId
        __typename
      }
      sessionUsers {
        items {
          id
          status
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          session {
            id
            date
            sessionNum
            createdAt
            updatedAt
            groupSessionsId
            __typename
          }
          createdAt
          updatedAt
          sessionsSessionUsersId
          userSessionUsersId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      groupSessionsId
      __typename
    }
  }
`;
export const updateSessions = /* GraphQL */ `
  mutation UpdateSessions(
    $input: UpdateSessionsInput!
    $condition: ModelSessionsConditionInput
  ) {
    updateSessions(input: $input, condition: $condition) {
      id
      date
      sessionNum
      group {
        id
        user {
          id
          username
          keyPhoto
          fullName
          phone
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          mentorType
          range
          Groups {
            nextToken
            __typename
          }
          comments {
            nextToken
            __typename
          }
          subComment {
            nextToken
            __typename
          }
          membersGroups {
            nextToken
            __typename
          }
          auth
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userLocationId
          userChurchId
          __typename
        }
        sections {
          items {
            id
            activeMembers
            initialDate
            state
            createdAt
            updatedAt
            groupSectionsId
            __typename
          }
          nextToken
          __typename
        }
        etareaGroup
        country
        distrite
        groupName
        description
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        initialDate
        finishDate
        members {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ministry
        language
        modality
        progress
        format
        subformat
        sessions {
          items {
            id
            date
            sessionNum
            createdAt
            updatedAt
            groupSessionsId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userGroupsId
        groupChurchId
        __typename
      }
      sessionUsers {
        items {
          id
          status
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          session {
            id
            date
            sessionNum
            createdAt
            updatedAt
            groupSessionsId
            __typename
          }
          createdAt
          updatedAt
          sessionsSessionUsersId
          userSessionUsersId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      groupSessionsId
      __typename
    }
  }
`;
export const deleteSessions = /* GraphQL */ `
  mutation DeleteSessions(
    $input: DeleteSessionsInput!
    $condition: ModelSessionsConditionInput
  ) {
    deleteSessions(input: $input, condition: $condition) {
      id
      date
      sessionNum
      group {
        id
        user {
          id
          username
          keyPhoto
          fullName
          phone
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          mentorType
          range
          Groups {
            nextToken
            __typename
          }
          comments {
            nextToken
            __typename
          }
          subComment {
            nextToken
            __typename
          }
          membersGroups {
            nextToken
            __typename
          }
          auth
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userLocationId
          userChurchId
          __typename
        }
        sections {
          items {
            id
            activeMembers
            initialDate
            state
            createdAt
            updatedAt
            groupSectionsId
            __typename
          }
          nextToken
          __typename
        }
        etareaGroup
        country
        distrite
        groupName
        description
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        initialDate
        finishDate
        members {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ministry
        language
        modality
        progress
        format
        subformat
        sessions {
          items {
            id
            date
            sessionNum
            createdAt
            updatedAt
            groupSessionsId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userGroupsId
        groupChurchId
        __typename
      }
      sessionUsers {
        items {
          id
          status
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          session {
            id
            date
            sessionNum
            createdAt
            updatedAt
            groupSessionsId
            __typename
          }
          createdAt
          updatedAt
          sessionsSessionUsersId
          userSessionUsersId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      groupSessionsId
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      keyPhoto
      fullName
      phone
      location {
        id
        country
        distrite
        createdAt
        updatedAt
        __typename
      }
      church {
        id
        name
        address
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        churchLocationId
        __typename
      }
      mentorType
      range
      Groups {
        items {
          id
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          sections {
            nextToken
            __typename
          }
          etareaGroup
          country
          distrite
          groupName
          description
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          initialDate
          finishDate
          members {
            nextToken
            __typename
          }
          ministry
          language
          modality
          progress
          format
          subformat
          sessions {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userGroupsId
          groupChurchId
          __typename
        }
        nextToken
        __typename
      }
      comments {
        items {
          id
          comment
          response {
            nextToken
            __typename
          }
          etareaGroup
          author {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          createdAt
          updatedAt
          userCommentsId
          __typename
        }
        nextToken
        __typename
      }
      subComment {
        items {
          id
          parentComment {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          comment
          etareaGroup
          author {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          createdAt
          updatedAt
          userSubCommentId
          commentResponseId
          __typename
        }
        nextToken
        __typename
      }
      membersGroups {
        items {
          id
          userID
          groupID
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          group {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      auth
      sessionUsers {
        items {
          id
          status
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          session {
            id
            date
            sessionNum
            createdAt
            updatedAt
            groupSessionsId
            __typename
          }
          createdAt
          updatedAt
          sessionsSessionUsersId
          userSessionUsersId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userLocationId
      userChurchId
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      keyPhoto
      fullName
      phone
      location {
        id
        country
        distrite
        createdAt
        updatedAt
        __typename
      }
      church {
        id
        name
        address
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        churchLocationId
        __typename
      }
      mentorType
      range
      Groups {
        items {
          id
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          sections {
            nextToken
            __typename
          }
          etareaGroup
          country
          distrite
          groupName
          description
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          initialDate
          finishDate
          members {
            nextToken
            __typename
          }
          ministry
          language
          modality
          progress
          format
          subformat
          sessions {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userGroupsId
          groupChurchId
          __typename
        }
        nextToken
        __typename
      }
      comments {
        items {
          id
          comment
          response {
            nextToken
            __typename
          }
          etareaGroup
          author {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          createdAt
          updatedAt
          userCommentsId
          __typename
        }
        nextToken
        __typename
      }
      subComment {
        items {
          id
          parentComment {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          comment
          etareaGroup
          author {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          createdAt
          updatedAt
          userSubCommentId
          commentResponseId
          __typename
        }
        nextToken
        __typename
      }
      membersGroups {
        items {
          id
          userID
          groupID
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          group {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      auth
      sessionUsers {
        items {
          id
          status
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          session {
            id
            date
            sessionNum
            createdAt
            updatedAt
            groupSessionsId
            __typename
          }
          createdAt
          updatedAt
          sessionsSessionUsersId
          userSessionUsersId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userLocationId
      userChurchId
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      keyPhoto
      fullName
      phone
      location {
        id
        country
        distrite
        createdAt
        updatedAt
        __typename
      }
      church {
        id
        name
        address
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        churchLocationId
        __typename
      }
      mentorType
      range
      Groups {
        items {
          id
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          sections {
            nextToken
            __typename
          }
          etareaGroup
          country
          distrite
          groupName
          description
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          initialDate
          finishDate
          members {
            nextToken
            __typename
          }
          ministry
          language
          modality
          progress
          format
          subformat
          sessions {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userGroupsId
          groupChurchId
          __typename
        }
        nextToken
        __typename
      }
      comments {
        items {
          id
          comment
          response {
            nextToken
            __typename
          }
          etareaGroup
          author {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          createdAt
          updatedAt
          userCommentsId
          __typename
        }
        nextToken
        __typename
      }
      subComment {
        items {
          id
          parentComment {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          comment
          etareaGroup
          author {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          createdAt
          updatedAt
          userSubCommentId
          commentResponseId
          __typename
        }
        nextToken
        __typename
      }
      membersGroups {
        items {
          id
          userID
          groupID
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          group {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      auth
      sessionUsers {
        items {
          id
          status
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          session {
            id
            date
            sessionNum
            createdAt
            updatedAt
            groupSessionsId
            __typename
          }
          createdAt
          updatedAt
          sessionsSessionUsersId
          userSessionUsersId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userLocationId
      userChurchId
      __typename
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      name
      country
      phone
      city
      email
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      name
      country
      phone
      city
      email
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      name
      country
      phone
      city
      email
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      user {
        id
        username
        keyPhoto
        fullName
        phone
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        mentorType
        range
        Groups {
          items {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          nextToken
          __typename
        }
        subComment {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        membersGroups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        auth
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userLocationId
        userChurchId
        __typename
      }
      sections {
        items {
          id
          group {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          activeMembers
          initialDate
          state
          createdAt
          updatedAt
          groupSectionsId
          __typename
        }
        nextToken
        __typename
      }
      etareaGroup
      country
      distrite
      groupName
      description
      church {
        id
        name
        address
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        churchLocationId
        __typename
      }
      initialDate
      finishDate
      members {
        items {
          id
          userID
          groupID
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          group {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ministry
      language
      modality
      progress
      format
      subformat
      sessions {
        items {
          id
          date
          sessionNum
          group {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          groupSessionsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userGroupsId
      groupChurchId
      __typename
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      user {
        id
        username
        keyPhoto
        fullName
        phone
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        mentorType
        range
        Groups {
          items {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          nextToken
          __typename
        }
        subComment {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        membersGroups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        auth
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userLocationId
        userChurchId
        __typename
      }
      sections {
        items {
          id
          group {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          activeMembers
          initialDate
          state
          createdAt
          updatedAt
          groupSectionsId
          __typename
        }
        nextToken
        __typename
      }
      etareaGroup
      country
      distrite
      groupName
      description
      church {
        id
        name
        address
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        churchLocationId
        __typename
      }
      initialDate
      finishDate
      members {
        items {
          id
          userID
          groupID
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          group {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ministry
      language
      modality
      progress
      format
      subformat
      sessions {
        items {
          id
          date
          sessionNum
          group {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          groupSessionsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userGroupsId
      groupChurchId
      __typename
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      user {
        id
        username
        keyPhoto
        fullName
        phone
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        mentorType
        range
        Groups {
          items {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          nextToken
          __typename
        }
        subComment {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        membersGroups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        auth
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userLocationId
        userChurchId
        __typename
      }
      sections {
        items {
          id
          group {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          activeMembers
          initialDate
          state
          createdAt
          updatedAt
          groupSectionsId
          __typename
        }
        nextToken
        __typename
      }
      etareaGroup
      country
      distrite
      groupName
      description
      church {
        id
        name
        address
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        churchLocationId
        __typename
      }
      initialDate
      finishDate
      members {
        items {
          id
          userID
          groupID
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          group {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ministry
      language
      modality
      progress
      format
      subformat
      sessions {
        items {
          id
          date
          sessionNum
          group {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          groupSessionsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userGroupsId
      groupChurchId
      __typename
    }
  }
`;
export const createSection = /* GraphQL */ `
  mutation CreateSection(
    $input: CreateSectionInput!
    $condition: ModelSectionConditionInput
  ) {
    createSection(input: $input, condition: $condition) {
      id
      group {
        id
        user {
          id
          username
          keyPhoto
          fullName
          phone
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          mentorType
          range
          Groups {
            nextToken
            __typename
          }
          comments {
            nextToken
            __typename
          }
          subComment {
            nextToken
            __typename
          }
          membersGroups {
            nextToken
            __typename
          }
          auth
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userLocationId
          userChurchId
          __typename
        }
        sections {
          items {
            id
            activeMembers
            initialDate
            state
            createdAt
            updatedAt
            groupSectionsId
            __typename
          }
          nextToken
          __typename
        }
        etareaGroup
        country
        distrite
        groupName
        description
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        initialDate
        finishDate
        members {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ministry
        language
        modality
        progress
        format
        subformat
        sessions {
          items {
            id
            date
            sessionNum
            createdAt
            updatedAt
            groupSessionsId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userGroupsId
        groupChurchId
        __typename
      }
      activeMembers
      initialDate
      state
      createdAt
      updatedAt
      groupSectionsId
      __typename
    }
  }
`;
export const updateSection = /* GraphQL */ `
  mutation UpdateSection(
    $input: UpdateSectionInput!
    $condition: ModelSectionConditionInput
  ) {
    updateSection(input: $input, condition: $condition) {
      id
      group {
        id
        user {
          id
          username
          keyPhoto
          fullName
          phone
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          mentorType
          range
          Groups {
            nextToken
            __typename
          }
          comments {
            nextToken
            __typename
          }
          subComment {
            nextToken
            __typename
          }
          membersGroups {
            nextToken
            __typename
          }
          auth
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userLocationId
          userChurchId
          __typename
        }
        sections {
          items {
            id
            activeMembers
            initialDate
            state
            createdAt
            updatedAt
            groupSectionsId
            __typename
          }
          nextToken
          __typename
        }
        etareaGroup
        country
        distrite
        groupName
        description
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        initialDate
        finishDate
        members {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ministry
        language
        modality
        progress
        format
        subformat
        sessions {
          items {
            id
            date
            sessionNum
            createdAt
            updatedAt
            groupSessionsId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userGroupsId
        groupChurchId
        __typename
      }
      activeMembers
      initialDate
      state
      createdAt
      updatedAt
      groupSectionsId
      __typename
    }
  }
`;
export const deleteSection = /* GraphQL */ `
  mutation DeleteSection(
    $input: DeleteSectionInput!
    $condition: ModelSectionConditionInput
  ) {
    deleteSection(input: $input, condition: $condition) {
      id
      group {
        id
        user {
          id
          username
          keyPhoto
          fullName
          phone
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          mentorType
          range
          Groups {
            nextToken
            __typename
          }
          comments {
            nextToken
            __typename
          }
          subComment {
            nextToken
            __typename
          }
          membersGroups {
            nextToken
            __typename
          }
          auth
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userLocationId
          userChurchId
          __typename
        }
        sections {
          items {
            id
            activeMembers
            initialDate
            state
            createdAt
            updatedAt
            groupSectionsId
            __typename
          }
          nextToken
          __typename
        }
        etareaGroup
        country
        distrite
        groupName
        description
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        initialDate
        finishDate
        members {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ministry
        language
        modality
        progress
        format
        subformat
        sessions {
          items {
            id
            date
            sessionNum
            createdAt
            updatedAt
            groupSessionsId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userGroupsId
        groupChurchId
        __typename
      }
      activeMembers
      initialDate
      state
      createdAt
      updatedAt
      groupSectionsId
      __typename
    }
  }
`;
export const createChurch = /* GraphQL */ `
  mutation CreateChurch(
    $input: CreateChurchInput!
    $condition: ModelChurchConditionInput
  ) {
    createChurch(input: $input, condition: $condition) {
      id
      name
      address
      location {
        id
        country
        distrite
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      churchLocationId
      __typename
    }
  }
`;
export const updateChurch = /* GraphQL */ `
  mutation UpdateChurch(
    $input: UpdateChurchInput!
    $condition: ModelChurchConditionInput
  ) {
    updateChurch(input: $input, condition: $condition) {
      id
      name
      address
      location {
        id
        country
        distrite
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      churchLocationId
      __typename
    }
  }
`;
export const deleteChurch = /* GraphQL */ `
  mutation DeleteChurch(
    $input: DeleteChurchInput!
    $condition: ModelChurchConditionInput
  ) {
    deleteChurch(input: $input, condition: $condition) {
      id
      name
      address
      location {
        id
        country
        distrite
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      churchLocationId
      __typename
    }
  }
`;
export const createForm = /* GraphQL */ `
  mutation CreateForm(
    $input: CreateFormInput!
    $condition: ModelFormConditionInput
  ) {
    createForm(input: $input, condition: $condition) {
      etareaGroup
      ministry
      language
      modality
      format
      subformat
      mentorType
      range
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateForm = /* GraphQL */ `
  mutation UpdateForm(
    $input: UpdateFormInput!
    $condition: ModelFormConditionInput
  ) {
    updateForm(input: $input, condition: $condition) {
      etareaGroup
      ministry
      language
      modality
      format
      subformat
      mentorType
      range
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteForm = /* GraphQL */ `
  mutation DeleteForm(
    $input: DeleteFormInput!
    $condition: ModelFormConditionInput
  ) {
    deleteForm(input: $input, condition: $condition) {
      etareaGroup
      ministry
      language
      modality
      format
      subformat
      mentorType
      range
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      country
      distrite
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      country
      distrite
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      country
      distrite
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      comment
      response {
        items {
          id
          parentComment {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          comment
          etareaGroup
          author {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          createdAt
          updatedAt
          userSubCommentId
          commentResponseId
          __typename
        }
        nextToken
        __typename
      }
      etareaGroup
      author {
        id
        username
        keyPhoto
        fullName
        phone
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        mentorType
        range
        Groups {
          items {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          nextToken
          __typename
        }
        subComment {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        membersGroups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        auth
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userLocationId
        userChurchId
        __typename
      }
      createdAt
      updatedAt
      userCommentsId
      __typename
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      comment
      response {
        items {
          id
          parentComment {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          comment
          etareaGroup
          author {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          createdAt
          updatedAt
          userSubCommentId
          commentResponseId
          __typename
        }
        nextToken
        __typename
      }
      etareaGroup
      author {
        id
        username
        keyPhoto
        fullName
        phone
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        mentorType
        range
        Groups {
          items {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          nextToken
          __typename
        }
        subComment {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        membersGroups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        auth
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userLocationId
        userChurchId
        __typename
      }
      createdAt
      updatedAt
      userCommentsId
      __typename
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      comment
      response {
        items {
          id
          parentComment {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          comment
          etareaGroup
          author {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          createdAt
          updatedAt
          userSubCommentId
          commentResponseId
          __typename
        }
        nextToken
        __typename
      }
      etareaGroup
      author {
        id
        username
        keyPhoto
        fullName
        phone
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        mentorType
        range
        Groups {
          items {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          nextToken
          __typename
        }
        subComment {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        membersGroups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        auth
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userLocationId
        userChurchId
        __typename
      }
      createdAt
      updatedAt
      userCommentsId
      __typename
    }
  }
`;
export const createSubComment = /* GraphQL */ `
  mutation CreateSubComment(
    $input: CreateSubCommentInput!
    $condition: ModelSubCommentConditionInput
  ) {
    createSubComment(input: $input, condition: $condition) {
      id
      parentComment {
        id
        comment
        response {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        etareaGroup
        author {
          id
          username
          keyPhoto
          fullName
          phone
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          mentorType
          range
          Groups {
            nextToken
            __typename
          }
          comments {
            nextToken
            __typename
          }
          subComment {
            nextToken
            __typename
          }
          membersGroups {
            nextToken
            __typename
          }
          auth
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userLocationId
          userChurchId
          __typename
        }
        createdAt
        updatedAt
        userCommentsId
        __typename
      }
      comment
      etareaGroup
      author {
        id
        username
        keyPhoto
        fullName
        phone
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        mentorType
        range
        Groups {
          items {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          nextToken
          __typename
        }
        subComment {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        membersGroups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        auth
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userLocationId
        userChurchId
        __typename
      }
      createdAt
      updatedAt
      userSubCommentId
      commentResponseId
      __typename
    }
  }
`;
export const updateSubComment = /* GraphQL */ `
  mutation UpdateSubComment(
    $input: UpdateSubCommentInput!
    $condition: ModelSubCommentConditionInput
  ) {
    updateSubComment(input: $input, condition: $condition) {
      id
      parentComment {
        id
        comment
        response {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        etareaGroup
        author {
          id
          username
          keyPhoto
          fullName
          phone
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          mentorType
          range
          Groups {
            nextToken
            __typename
          }
          comments {
            nextToken
            __typename
          }
          subComment {
            nextToken
            __typename
          }
          membersGroups {
            nextToken
            __typename
          }
          auth
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userLocationId
          userChurchId
          __typename
        }
        createdAt
        updatedAt
        userCommentsId
        __typename
      }
      comment
      etareaGroup
      author {
        id
        username
        keyPhoto
        fullName
        phone
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        mentorType
        range
        Groups {
          items {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          nextToken
          __typename
        }
        subComment {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        membersGroups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        auth
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userLocationId
        userChurchId
        __typename
      }
      createdAt
      updatedAt
      userSubCommentId
      commentResponseId
      __typename
    }
  }
`;
export const deleteSubComment = /* GraphQL */ `
  mutation DeleteSubComment(
    $input: DeleteSubCommentInput!
    $condition: ModelSubCommentConditionInput
  ) {
    deleteSubComment(input: $input, condition: $condition) {
      id
      parentComment {
        id
        comment
        response {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        etareaGroup
        author {
          id
          username
          keyPhoto
          fullName
          phone
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          mentorType
          range
          Groups {
            nextToken
            __typename
          }
          comments {
            nextToken
            __typename
          }
          subComment {
            nextToken
            __typename
          }
          membersGroups {
            nextToken
            __typename
          }
          auth
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userLocationId
          userChurchId
          __typename
        }
        createdAt
        updatedAt
        userCommentsId
        __typename
      }
      comment
      etareaGroup
      author {
        id
        username
        keyPhoto
        fullName
        phone
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        mentorType
        range
        Groups {
          items {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          nextToken
          __typename
        }
        subComment {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        membersGroups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        auth
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userLocationId
        userChurchId
        __typename
      }
      createdAt
      updatedAt
      userSubCommentId
      commentResponseId
      __typename
    }
  }
`;
export const createSessionUser = /* GraphQL */ `
  mutation CreateSessionUser(
    $input: CreateSessionUserInput!
    $condition: ModelSessionUserConditionInput
  ) {
    createSessionUser(input: $input, condition: $condition) {
      id
      status
      user {
        id
        username
        keyPhoto
        fullName
        phone
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        mentorType
        range
        Groups {
          items {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          nextToken
          __typename
        }
        subComment {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        membersGroups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        auth
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userLocationId
        userChurchId
        __typename
      }
      session {
        id
        date
        sessionNum
        group {
          id
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          sections {
            nextToken
            __typename
          }
          etareaGroup
          country
          distrite
          groupName
          description
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          initialDate
          finishDate
          members {
            nextToken
            __typename
          }
          ministry
          language
          modality
          progress
          format
          subformat
          sessions {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userGroupsId
          groupChurchId
          __typename
        }
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        groupSessionsId
        __typename
      }
      createdAt
      updatedAt
      sessionsSessionUsersId
      userSessionUsersId
      __typename
    }
  }
`;
export const updateSessionUser = /* GraphQL */ `
  mutation UpdateSessionUser(
    $input: UpdateSessionUserInput!
    $condition: ModelSessionUserConditionInput
  ) {
    updateSessionUser(input: $input, condition: $condition) {
      id
      status
      user {
        id
        username
        keyPhoto
        fullName
        phone
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        mentorType
        range
        Groups {
          items {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          nextToken
          __typename
        }
        subComment {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        membersGroups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        auth
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userLocationId
        userChurchId
        __typename
      }
      session {
        id
        date
        sessionNum
        group {
          id
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          sections {
            nextToken
            __typename
          }
          etareaGroup
          country
          distrite
          groupName
          description
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          initialDate
          finishDate
          members {
            nextToken
            __typename
          }
          ministry
          language
          modality
          progress
          format
          subformat
          sessions {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userGroupsId
          groupChurchId
          __typename
        }
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        groupSessionsId
        __typename
      }
      createdAt
      updatedAt
      sessionsSessionUsersId
      userSessionUsersId
      __typename
    }
  }
`;
export const deleteSessionUser = /* GraphQL */ `
  mutation DeleteSessionUser(
    $input: DeleteSessionUserInput!
    $condition: ModelSessionUserConditionInput
  ) {
    deleteSessionUser(input: $input, condition: $condition) {
      id
      status
      user {
        id
        username
        keyPhoto
        fullName
        phone
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        mentorType
        range
        Groups {
          items {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          nextToken
          __typename
        }
        subComment {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        membersGroups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        auth
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userLocationId
        userChurchId
        __typename
      }
      session {
        id
        date
        sessionNum
        group {
          id
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          sections {
            nextToken
            __typename
          }
          etareaGroup
          country
          distrite
          groupName
          description
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          initialDate
          finishDate
          members {
            nextToken
            __typename
          }
          ministry
          language
          modality
          progress
          format
          subformat
          sessions {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userGroupsId
          groupChurchId
          __typename
        }
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        groupSessionsId
        __typename
      }
      createdAt
      updatedAt
      sessionsSessionUsersId
      userSessionUsersId
      __typename
    }
  }
`;
export const createGroupsUsers = /* GraphQL */ `
  mutation CreateGroupsUsers(
    $input: CreateGroupsUsersInput!
    $condition: ModelGroupsUsersConditionInput
  ) {
    createGroupsUsers(input: $input, condition: $condition) {
      id
      userID
      groupID
      user {
        id
        username
        keyPhoto
        fullName
        phone
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        mentorType
        range
        Groups {
          items {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          nextToken
          __typename
        }
        subComment {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        membersGroups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        auth
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userLocationId
        userChurchId
        __typename
      }
      group {
        id
        user {
          id
          username
          keyPhoto
          fullName
          phone
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          mentorType
          range
          Groups {
            nextToken
            __typename
          }
          comments {
            nextToken
            __typename
          }
          subComment {
            nextToken
            __typename
          }
          membersGroups {
            nextToken
            __typename
          }
          auth
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userLocationId
          userChurchId
          __typename
        }
        sections {
          items {
            id
            activeMembers
            initialDate
            state
            createdAt
            updatedAt
            groupSectionsId
            __typename
          }
          nextToken
          __typename
        }
        etareaGroup
        country
        distrite
        groupName
        description
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        initialDate
        finishDate
        members {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ministry
        language
        modality
        progress
        format
        subformat
        sessions {
          items {
            id
            date
            sessionNum
            createdAt
            updatedAt
            groupSessionsId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userGroupsId
        groupChurchId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGroupsUsers = /* GraphQL */ `
  mutation UpdateGroupsUsers(
    $input: UpdateGroupsUsersInput!
    $condition: ModelGroupsUsersConditionInput
  ) {
    updateGroupsUsers(input: $input, condition: $condition) {
      id
      userID
      groupID
      user {
        id
        username
        keyPhoto
        fullName
        phone
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        mentorType
        range
        Groups {
          items {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          nextToken
          __typename
        }
        subComment {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        membersGroups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        auth
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userLocationId
        userChurchId
        __typename
      }
      group {
        id
        user {
          id
          username
          keyPhoto
          fullName
          phone
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          mentorType
          range
          Groups {
            nextToken
            __typename
          }
          comments {
            nextToken
            __typename
          }
          subComment {
            nextToken
            __typename
          }
          membersGroups {
            nextToken
            __typename
          }
          auth
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userLocationId
          userChurchId
          __typename
        }
        sections {
          items {
            id
            activeMembers
            initialDate
            state
            createdAt
            updatedAt
            groupSectionsId
            __typename
          }
          nextToken
          __typename
        }
        etareaGroup
        country
        distrite
        groupName
        description
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        initialDate
        finishDate
        members {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ministry
        language
        modality
        progress
        format
        subformat
        sessions {
          items {
            id
            date
            sessionNum
            createdAt
            updatedAt
            groupSessionsId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userGroupsId
        groupChurchId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGroupsUsers = /* GraphQL */ `
  mutation DeleteGroupsUsers(
    $input: DeleteGroupsUsersInput!
    $condition: ModelGroupsUsersConditionInput
  ) {
    deleteGroupsUsers(input: $input, condition: $condition) {
      id
      userID
      groupID
      user {
        id
        username
        keyPhoto
        fullName
        phone
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        mentorType
        range
        Groups {
          items {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          nextToken
          __typename
        }
        subComment {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        membersGroups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        auth
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userLocationId
        userChurchId
        __typename
      }
      group {
        id
        user {
          id
          username
          keyPhoto
          fullName
          phone
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          mentorType
          range
          Groups {
            nextToken
            __typename
          }
          comments {
            nextToken
            __typename
          }
          subComment {
            nextToken
            __typename
          }
          membersGroups {
            nextToken
            __typename
          }
          auth
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userLocationId
          userChurchId
          __typename
        }
        sections {
          items {
            id
            activeMembers
            initialDate
            state
            createdAt
            updatedAt
            groupSectionsId
            __typename
          }
          nextToken
          __typename
        }
        etareaGroup
        country
        distrite
        groupName
        description
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        initialDate
        finishDate
        members {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ministry
        language
        modality
        progress
        format
        subformat
        sessions {
          items {
            id
            date
            sessionNum
            createdAt
            updatedAt
            groupSessionsId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userGroupsId
        groupChurchId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
