<template>
  <div class="ForgotModal"> 
      <!-- modal para agregar iglesias -->
          <div id="Olvido" class="boxModal animated fadeIn"> 
                  <div class="contentModal animated fadeInUp">
                    <div class="btnCerrar" @click="cerrarModal()">X</div> 
                    <div class="row">
                        <div class="col-md-6 text-left flexCenter">        
                            <Lang/>                          
                        </div>
                        <div class="col-md-6 text-right"> 
                            <nav class="menuUser Logout">
                                <router-link to="#" @click="openModal('Register')" class="nameMenu">{{ $t("auth.register_button") }} </router-link>
                                <router-link to="#" @click="openModal('Login')" class="nameMenu">{{ $t("auth.sign_in_button") }} </router-link>
                            </nav>
                        </div>
                    </div>     
                    <div class="row">
                        <div class="col-md-12">
                              <h3 class="Adicional">{{ $t("auth.change_password") }}</h3> 
                            <input type="email"  class="text-center" name="emailO" id="emailO" v-model="username" placeholder="E-mail"> 
                             <div class="msjerror">{{msjError}}</div>
                        </div>    
                        <div class="col-md-12"><button @click="forgot()" class="btn Cancelar">{{ $t("auth.reset_password") }}</button></div>  
                        
                    </div>            
                  </div>            
          </div>
      <!-- modal para agregar iglesias --> 
      <!-- modal aviso -->
                  <AlertModal id="aviso-olvido" class="boxModal animated fadeIn aviso"
                    :tituloMsj='$t("auth.reset_password")'
                    :msj='msjAlert'
                  />         
      <!-- modal aviso -->
          <!-- modal aviso -->
                  <ForgotPassword id="confirm-olvido"  class="boxModal animated fadeIn aviso"
                    :tituloMsj='$t("auth.reset_password")'
                    :msj='msjAlert' 
                    :msjerr='msjError'
                    v-on:emit="confirCode"
                  />         
      <!-- modal aviso -->
  </div> 
</template>
<script>   
import ForgotPassword from '@/components/modal/ForgotPassword' 
  import Lang from '@/components/lang/Language'
import { Auth} from 'aws-amplify';
export default {
  name: 'ForgotModal',
  components: {  
    Lang, 
    ForgotPassword   
  },  
  data: () => ({  
    cont:0,
    username:'',
    msjError:"",
    msjAlert:""
  }), 
  methods:{   
    openModal(id){ 
        this.cerrarModal()
         setTimeout(() => {          
            $('#'+id).show()   
            $('#'+id).removeClass('fadeOut').addClass('fadeIn')
            $('#'+id+' .contentModal').addClass('fadeInUp').removeClass('fadeOutDown')
        }, 1000);
    },
    cerrarModal(){ 
        $('.boxModal').removeClass('fadeIn').addClass('fadeOut')
        $('.contentModal').removeClass('fadeInUp').addClass('fadeOutDown')
        setTimeout(() => {
          $('.boxModal').removeAttr('style')
          $('.contentModal').removeClass('fadeOutDown')
        }, 1000);
        
    },
     confirCode: async function (code , newP, rNewP) {
    
      if(newP==rNewP){
        if(code) {
          $('#loaderChase').show()  
          Auth.forgotPasswordSubmit(this.username, code, newP)
          .then(data => {
            console.log(data)      
              $('#loaderChase').hide()
              this.msjAlert=this.$t("auth.reset_password_ok");
              this.openModal('aviso-olvido') 
            })
          .catch(err => {
            console.log(err) 
              $('#loaderChase').hide()  
                this.msjAlert=err.message 
                this.openModal('confirm-olvido') 
            });
        }
      } else{
        this.msjError=this.$t("auth.errorchange")
        $(".msjerror").show()
      } 
    },
    forgot(){ 
      if(this.username){
        $('#loaderChase').show()
        Auth.forgotPassword(this.username)
        .then(data => {
          console.log(data) 
            this.msjAlert=this.$t("auth.Forgoted");
            this.openModal('confirm-olvido') 
          $('#loaderChase').hide()
        })
        .catch(err => {
          console.log(err)
          
          this.msjAlert=err.message;
          this.openModal('aviso-olvido') 
          $('#loaderChase').hide()
        });
      }else{
        this.msjError=this.$t("modal.Required_item")
        $(".msjerror").show()
      }
     
    }
  },
}
</script>