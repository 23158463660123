<template>
  <div class="ConfirmInpModal"> 
       <!-- modal aviso --> 
                  <div class="contentModal animated fadeInUp"> 
                      <div class="row"> 
                        
                          <div class="col-md-12"> 
                              <div class="titModal">{{tituloMsj}}</div>
                          </div>
                          <div class="col-md-12"> 
                              <p class="textModal" id="textAviso">{{msj}}</p>
                              <input type="text" v-model="code" placeholder="000000" maxlength="6">
                              <div class="boxBtnModal">
                                <button class="btnSelect Guardar" v-on:click="$emit('emit',code)">{{ $t("button.Confirm_button") }}</button>
                                <button class="btnSelect Cancelar" @click="cerrarModal()">{{ $t("button.Cancel_button") }}</button>
                              </div>
                              
                          </div>
                      </div>                   
                  </div>          

      <!-- modal aviso -->
  </div> 
</template>
<script>  
  
export default {
  name: 'ConfirmInpModal',
  components: {      
  },  
  data: () => ({  
    cont:0,
    code:""
  }), 
  methods:{    
    cerrarModal(){ 
        $('.boxModal').removeClass('fadeIn').addClass('fadeOut')
        $('.contentModal').removeClass('fadeInUp').addClass('fadeOutDown')
        setTimeout(() => {
          $('.boxModal').removeAttr('style')
          $('.contentModal').removeClass('fadeOutDown')
        }, 1000);
        
    },  
  },
  props: { 
      msj: {
        type: Text,
        default: 'Confirm'
      }, 
      tituloMsj: {
        type: Text,
        default: 'Confirm'
      },  
    }, 
}
</script>