import { createStore } from 'vuex'
import { loadLanguageAsync } from "@/i18n";
import { listUsers } from '@/graphql/queries';
import { API,graphqlOperation } from 'aws-amplify';

export default createStore({
  state: {
    locale: "es",
    currentUser:null,
    currentGroup:null,
    locales: [
      { value: "es", label: "languages.es" },
      { value: "pt", label: "languages.pt" },
    ],
  },
  getters: {
    currentLocale: (state) => state.locale,
    currentGroup: (state) => state.currentGroup,
    availableLocales: (state) => state.locales,
    currentUser: (state)  => state.currentUser/*{
      return state.currentUser;
    }*/
  },
  mutations: {
  setLocale: (state, locale) => {
    localStorage.setItem("locale", locale);
    loadLanguageAsync(locale);
    state.locale = locale;
  },
  setCurrentUser(state, payload) {
    state.currentUser = payload;
  },
  setCurrentGroup(state, payload) {
    state.currentGroup = payload;
  }
  },
  actions: {
    async getCurrentUser({ commit, dispatch }, email) {
      let user = null;
      console.log('email:',email);
      try {
        user = await API.graphql(
          graphqlOperation(listUsers, {
            first: 1,
            filter: { username: { eq: email } },
          })
        );
        // email.user = user.data.listUsers.items[0];
        commit("setCurrentUser", user.data.listUsers.items[0]);        
      } catch (e) {
        console.log("error while fetching", e);
        return;
      }

    },
  },
  modules: {
  }
})
