<template>
  <div class="bgBlock" id="loaderChase">
      <div class="sk-chase " >
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
    </div>
  </div>
</template>
<script>  
  
export default {
  name: 'LoaderChase',
  components: {      
  }   
}
</script>