<template>
  <div class="WhatsApp"> 
       <img class="whasappBtn" @click="openWhatsApp()" src="../../assets/img/icons/whatsApp.png" alt="whasap">
  </div> 
</template>
<script>  
  
export default {
  name: 'WhatsApp',
  components: {      
  },  
  data: () => ({  
    cont:0
  }), 
  methods:{   
    openWhatsApp(){ 
        window.open('https://walink.co/91a705')
    },  
  },
}
</script>