
<template>
  <a class="boxDrop noIconD">
    <img class="imgLanMenu" alt="Español" :src="'/img/icons/' + (langs ? langs : 'es') + '.png'">{{ langs }}

    <div class="boxDropCont animated fadeIn">
      <div class="displayFlex">
        <div class="linkLang" v-for="lang of availableLocales" :key="lang.val" @click="classLang(lang.value)">
          <div @click.prevent="setLocale(lang.value)">
            <img class="imgLanMenu" :alt="lang.label" :src="'/img/icons/' + lang.value + '.png'"> {{ $t(lang.label) }}
          </div>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Lang',
  components: {
  },
  data: () => ({
    cont: 0,
    langs: ""
  }),
  computed: {
    ...mapGetters(['availableLocales', 'currentLocale'])
  },
  mounted: function () {
    this.init();
  },
  methods: {
    ...mapMutations(['setLocale']),
    init() {
      setTimeout(() => {
        this.langs = $('html').attr('lang')
      }, 100);
    },
    classLang(lang) {
      $('body').removeAttr('class').addClass(lang)
      this.init()
    }
  },
}
</script>