<template>
  <div class="menu"> 
    <div class="row menuTop" @click="menuClose()">
        <div class="col-md-6 col-sm-6 text-left">
            <router-link to="/">
                <img class="imgLogoMenu" alt=" logo" :src='$t("logo.menu")'> 
            </router-link>
        </div>
        <div class="col-md-6 col-sm-6 text-right">
            <nav v-if="currentUser" class="menuUser Login" id="menuUser">
                <router-link to="#" class="nameMenu boxDrop black">{{ $t("menu.hi") }}, {{ currentUser.fullName }}
                    <div class="boxDropCont animated fadeIn">
                        <div class="displayFlex">
                            <!-- <router-link to="#">Perfil</router-link> -->
                            <router-link to="/perfil">{{ $t("menu.perfil") }}</router-link> 
                            <router-link to="/mis-grupos">{{ $t("menu.Mis_grupos") }}</router-link> 
                            <router-link v-if="currentUser.range!=''" to="/Reporte-iwin">{{ $t("menu.Reporte_Iwin") }}</router-link> 
                            <router-link v-if="currentUser.range=='Admin'" to="/Admin">{{ $t("menu.Admin_de_usuarios") }}</router-link> 
                            <router-link to="/" @click="Logout()">{{ $t("auth.log_out") }}</router-link> 
                        </div>
                    </div>
                </router-link> |
                <router-link to="#" ><div class="imgUserMenu" id="imgMenuUser" alt=" logo" v-bind:style="'background:url('+picUrl+')'" @click="loadImg()"   @error="imgPerfil()" ></div> </router-link>
            </nav>
            <nav v-if="!currentUser" class="menuUser Logout" id="menuLogin">
                <router-link to="#" @click="openModal('Register')" class="nameMenu">{{ $t("auth.register_button") }}</router-link>
                <router-link to="#" @click="openModal('Login')" class="nameMenu">{{ $t("auth.sign_in_button") }}</router-link>
            </nav>
        </div> 
    </div>  
    <!-- menu escritorio  -->
    <div class="row menuNav" >
        <div class="col-md-6 text-left">            
            <nav>
                <!-- <router-link to="/#Nosotros" >{{ $t("menu.Nosotros") }}</router-link> -->
                <!-- <router-link to="/#Recursos">{{ $t("menu.Recursos") }}</router-link> -->
                <router-link to="/#Expositores">{{ $t("menu.Expositores") }}</router-link> 
                <!-- <router-link to="/#Testimonios">{{ $t("menu.Testimonios") }}</router-link> -->
                <router-link to="/#Contacto">{{ $t("menu.Contacto") }}</router-link> 
            </nav>
        </div>
        <div class="col-md-6 text-right">                    
            <nav >
                <div v-if="currentUser"> 
                    <router-link to="#" class="boxDrop white">{{ $t("menu.Taller_de_mentoreo") }}
                        <div class="boxDropCont animated fadeIn">
                            <div class="displayFlex">                            
                                <router-link to="/taller-de-mentoreo/?1">{{ $t("menu.Sesion") }} 1</router-link>
                                <router-link to="/taller-de-mentoreo/?2">{{ $t("menu.Sesion") }} 2</router-link>
                                <router-link to="/taller-de-mentoreo/?3">{{ $t("menu.Sesion") }} 3</router-link>  
                            </div>
                        </div>
                    </router-link>
                    <router-link v-if="currentUser.range=='Admin' || (currentUser.mentorType !='Mentoreado' && currentUser.mentorType !='Facilitador')" to="/mentoreo-jovenes">{{ $t("menu.Mentoreo_jovenes") }}</router-link>
                    <router-link v-if="currentUser.range=='Admin' || currentUser.mentorType =='Mentoreado' || currentUser.mentorType =='Facilitador'" to="/mentoreo-adulto">{{ $t("menu.Mentoreo_adulto") }}</router-link> 
                    <router-link to="/Mentorlink">{{ $t("menu.Mentorlink") }}</router-link>
                <Lang />
                </div>
                <Lang v-if="!currentUser"/>
            </nav>
        </div>
    </div> 
    <!-- menu escritorio  -->
    <!-- menu movil  -->
    <div class=" menuNavMovil">
        <div class="btnMenuMovil">
            <button><img class="menuResp" @click="menuOpen()" src="../assets/img/icons/menu-white.png" alt=""> </button>
            <div class=" text-left menuNavResp animated fadeInLeft">          
                <div class="closeMenuM" @click="menuClose()">X</div>  
                <nav @click="menuClose()">
                    <router-link to="/#Nosotros" >{{ $t("menu.Nosotros") }}</router-link>
                    <!-- <router-link to="/#Recursos">{{ $t("menu.Recursos") }}</router-link> -->
                    <router-link to="/#Expositores">{{ $t("menu.Expositores") }}</router-link> 
                    <!-- <router-link to="/#Testimonios">{{ $t("menu.Testimonios") }}</router-link> -->
                    <router-link to="/#Contacto">{{ $t("menu.Contacto") }}</router-link> 
                    <router-link to="#"   class="boxDrop white" id="TallerMentoreoM"  @click="MenuAcordeon('TallerMentoreoM')">                        
                        <span class="iconDrop white" >{{ $t("menu.Taller_de_mentoreo") }}</span>
                    </router-link>
                        <div class="boxDropContMovil"> 
                            <router-link to="/taller-de-mentoreo/?1">{{ $t("menu.Sesion") }} 1</router-link>
                            <router-link to="/taller-de-mentoreo/?2">{{ $t("menu.Sesion") }} 2</router-link>
                            <router-link to="/taller-de-mentoreo/?3">{{ $t("menu.Sesion") }} 3</router-link>  
                        </div>
                    <router-link to="/mentoreo-jovenes">{{ $t("menu.Mentoreo_jovenes") }}</router-link>
                    <router-link to="/mentoreo-adulto">{{ $t("menu.Mentoreo_adulto") }}</router-link> 
                    <router-link v-if="haveGroups" to="/Mentorlink">{{ $t("menu.Mentorlink") }}</router-link>
                </nav>
            </div>  
            <div class="bgClose animated fadeIn" @click="menuClose()"></div>
        </div>
        
        <Lang/>
    </div> 
    <!-- menu movil  -->
    <!-- login modal -->
    <LoginModal/>
    <!-- login modal -->
    <!-- Register modal -->
    <RegisterModal/>
    <!-- Register modal -->
    <!-- olvido modal -->
    <ForgotModal/>
    <!-- olvido modal -->
  </div>
</template>
<script>  
import LoginModal from '@/components/modal/Login'
import RegisterModal from '@/components/modal/Register'
import ForgotModal from '@/components/modal/Forgot'
import Lang from '@/components/lang/Language'
import { mapGetters, mapMutations, mapActions } from 'vuex' 
import { Auth, Storage } from 'aws-amplify';

export default {
  name: 'Menu',
  components: {
    LoginModal,
    RegisterModal,
    ForgotModal,
    Lang     
  },  
  data: () => ({  
    cont:0,
    picUrl:"/img/icons/profile-icon-grande.png",
    haveGroups: false
  }),
   async created() {

    //const user = await Auth.currentAuthenticatedUser();
        try {
            //  user = await Auth.currentAuthenticatedUser({
            //     bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
            // });

            // const { attributes } = user
            // console.log('attributes', attributes)

            const { attributes } = await Auth.currentAuthenticatedUser();

            await this.getCurrentUser(attributes.email)
            console.log('currentUser', this.currentUser);
            //this.haveGroups = this.currentUser.membersGroups.items != null

            setTimeout(() => {
                this.getImgUser(this.currentUser)
            }, 1000);       
        } catch (err) {
            console.log('-----',err);
        }
 

  },
  computed: {
    ...mapGetters(['availableLocales', 'currentLocale', 'currentUser'])
  },  
  methods:{
    ...mapActions(["getCurrentUser"]),
    ...mapMutations(['setLocale']),
    openModal(id){ 
        $('#'+id).show()   
        $('#'+id).removeClass('fadeOut').addClass('fadeIn')
        $('#'+id+' .contentModal').addClass('fadeInUp').removeClass('fadeOutDown')
    },
    async getImgUser(currentUser){       
       this.picUrl = await Storage.get(currentUser.keyPhoto);  
    } , 
    loadImg(){
        this.getImgUser(this.currentUser) 
    },
    imgPerfil(){
        this.picUrl="/img/icons/profile-icon-grande.png"
    },  
    menuOpen(){ 
        $('.menuNavResp , .bgClose').show()   
        $('.menuNavResp').removeClass('fadeOutLeft').addClass('fadeInLeft')
        $('.bgClose').addClass('fadeIn').removeClass('fadeOut')
    },
    menuClose(){  
        $('.menuNavResp').removeClass(' fadeInLeft').addClass('fadeOutLeft')
        $('.bgClose').addClass(' fadeOut').removeClass('fadeIn')
        setTimeout(() => {            
            $('.menuNavResp , .bgClose').removeAttr('style')  
        }, 500);
    },
    MenuAcordeon(id){
        var acc = document.getElementById(id);  
            //acc.classList.toggle("activeAc");
            var panel = acc.nextElementSibling;  
            if (panel.style.maxHeight) { 
                $('.boxDropContMovil').removeAttr('style')
            } else {
                panel.style.maxHeight = panel.scrollHeight + "px";
            }   
    }, 
    async Logout(){
        $('#menuLogin').css({'display':'flex'})
        $('#menuUser').css({'display':'none'})
        try {
            await Auth.signOut();
            this.$store.commit("setCurrentUser", null);
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }
  },
}
</script>