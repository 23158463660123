<template>
  <Menu/>
  <router-view/>
  
    <!-- WhatsApp --> 
    <WhatsApp/>
    <!-- WhatsApp --> 
  <Footer/>
  
      <!-- loader -->
      <LoaderChase/>
      <!-- loader --> 
</template>


 <script>
// @ is an alias to /src 
import Menu from '@/components/Menu.vue'
import LoaderChase from '@/components/loaders/LoaderChase'  
import Footer from '@/components/Footer.vue'
import WhatsApp from '@/components/buttons/Whatsapp'

export default {
  name: 'App',
  components: { 
    Menu,
    Footer,
    WhatsApp,
    LoaderChase, 
  }
}
</script>
