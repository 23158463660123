/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSessions = /* GraphQL */ `
  query GetSessions($id: ID!) {
    getSessions(id: $id) {
      id
      date
      sessionNum
      group {
        id
        user {
          id
          username
          keyPhoto
          fullName
          phone
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          mentorType
          range
          Groups {
            nextToken
            __typename
          }
          comments {
            nextToken
            __typename
          }
          subComment {
            nextToken
            __typename
          }
          membersGroups {
            nextToken
            __typename
          }
          auth
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userLocationId
          userChurchId
          __typename
        }
        sections {
          items {
            id
            activeMembers
            initialDate
            state
            createdAt
            updatedAt
            groupSectionsId
            __typename
          }
          nextToken
          __typename
        }
        etareaGroup
        country
        distrite
        groupName
        description
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        initialDate
        finishDate
        members {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ministry
        language
        modality
        progress
        format
        subformat
        sessions {
          items {
            id
            date
            sessionNum
            createdAt
            updatedAt
            groupSessionsId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userGroupsId
        groupChurchId
        __typename
      }
      sessionUsers {
        items {
          id
          status
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          session {
            id
            date
            sessionNum
            createdAt
            updatedAt
            groupSessionsId
            __typename
          }
          createdAt
          updatedAt
          sessionsSessionUsersId
          userSessionUsersId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      groupSessionsId
      __typename
    }
  }
`;
export const listSessions = /* GraphQL */ `
  query ListSessions(
    $filter: ModelSessionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        sessionNum
        group {
          id
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          sections {
            nextToken
            __typename
          }
          etareaGroup
          country
          distrite
          groupName
          description
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          initialDate
          finishDate
          members {
            nextToken
            __typename
          }
          ministry
          language
          modality
          progress
          format
          subformat
          sessions {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userGroupsId
          groupChurchId
          __typename
        }
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        groupSessionsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      keyPhoto
      fullName
      phone
      location {
        id
        country
        distrite
        createdAt
        updatedAt
        __typename
      }
      church {
        id
        name
        address
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        churchLocationId
        __typename
      }
      mentorType
      range
      Groups {
        items {
          id
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          sections {
            nextToken
            __typename
          }
          etareaGroup
          country
          distrite
          groupName
          description
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          initialDate
          finishDate
          members {
            nextToken
            __typename
          }
          ministry
          language
          modality
          progress
          format
          subformat
          sessions {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userGroupsId
          groupChurchId
          __typename
        }
        nextToken
        __typename
      }
      comments {
        items {
          id
          comment
          response {
            nextToken
            __typename
          }
          etareaGroup
          author {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          createdAt
          updatedAt
          userCommentsId
          __typename
        }
        nextToken
        __typename
      }
      subComment {
        items {
          id
          parentComment {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          comment
          etareaGroup
          author {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          createdAt
          updatedAt
          userSubCommentId
          commentResponseId
          __typename
        }
        nextToken
        __typename
      }
      membersGroups {
        items {
          id
          userID
          groupID
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          group {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      auth
      sessionUsers {
        items {
          id
          status
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          session {
            id
            date
            sessionNum
            createdAt
            updatedAt
            groupSessionsId
            __typename
          }
          createdAt
          updatedAt
          sessionsSessionUsersId
          userSessionUsersId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userLocationId
      userChurchId
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        keyPhoto
        fullName
        phone
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        mentorType
        range
        Groups {
          items {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          nextToken
          __typename
        }
        subComment {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        membersGroups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        auth
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userLocationId
        userChurchId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      name
      country
      phone
      city
      email
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        country
        phone
        city
        email
        message
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      user {
        id
        username
        keyPhoto
        fullName
        phone
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        mentorType
        range
        Groups {
          items {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          nextToken
          __typename
        }
        subComment {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        membersGroups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        auth
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userLocationId
        userChurchId
        __typename
      }
      sections {
        items {
          id
          group {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          activeMembers
          initialDate
          state
          createdAt
          updatedAt
          groupSectionsId
          __typename
        }
        nextToken
        __typename
      }
      etareaGroup
      country
      distrite
      groupName
      description
      church {
        id
        name
        address
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        churchLocationId
        __typename
      }
      initialDate
      finishDate
      members {
        items {
          id
          userID
          groupID
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          group {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ministry
      language
      modality
      progress
      format
      subformat
      sessions {
        items {
          id
          date
          sessionNum
          group {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          groupSessionsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userGroupsId
      groupChurchId
      __typename
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          id
          username
          keyPhoto
          fullName
          phone
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          mentorType
          range
          Groups {
            nextToken
            __typename
          }
          comments {
            nextToken
            __typename
          }
          subComment {
            nextToken
            __typename
          }
          membersGroups {
            nextToken
            __typename
          }
          auth
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userLocationId
          userChurchId
          __typename
        }
        sections {
          items {
            id
            activeMembers
            initialDate
            state
            createdAt
            updatedAt
            groupSectionsId
            __typename
          }
          nextToken
          __typename
        }
        etareaGroup
        country
        distrite
        groupName
        description
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        initialDate
        finishDate
        members {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ministry
        language
        modality
        progress
        format
        subformat
        sessions {
          items {
            id
            date
            sessionNum
            createdAt
            updatedAt
            groupSessionsId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userGroupsId
        groupChurchId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSection = /* GraphQL */ `
  query GetSection($id: ID!) {
    getSection(id: $id) {
      id
      group {
        id
        user {
          id
          username
          keyPhoto
          fullName
          phone
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          mentorType
          range
          Groups {
            nextToken
            __typename
          }
          comments {
            nextToken
            __typename
          }
          subComment {
            nextToken
            __typename
          }
          membersGroups {
            nextToken
            __typename
          }
          auth
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userLocationId
          userChurchId
          __typename
        }
        sections {
          items {
            id
            activeMembers
            initialDate
            state
            createdAt
            updatedAt
            groupSectionsId
            __typename
          }
          nextToken
          __typename
        }
        etareaGroup
        country
        distrite
        groupName
        description
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        initialDate
        finishDate
        members {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ministry
        language
        modality
        progress
        format
        subformat
        sessions {
          items {
            id
            date
            sessionNum
            createdAt
            updatedAt
            groupSessionsId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userGroupsId
        groupChurchId
        __typename
      }
      activeMembers
      initialDate
      state
      createdAt
      updatedAt
      groupSectionsId
      __typename
    }
  }
`;
export const listSections = /* GraphQL */ `
  query ListSections(
    $filter: ModelSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group {
          id
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          sections {
            nextToken
            __typename
          }
          etareaGroup
          country
          distrite
          groupName
          description
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          initialDate
          finishDate
          members {
            nextToken
            __typename
          }
          ministry
          language
          modality
          progress
          format
          subformat
          sessions {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userGroupsId
          groupChurchId
          __typename
        }
        activeMembers
        initialDate
        state
        createdAt
        updatedAt
        groupSectionsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChurch = /* GraphQL */ `
  query GetChurch($id: ID!) {
    getChurch(id: $id) {
      id
      name
      address
      location {
        id
        country
        distrite
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      churchLocationId
      __typename
    }
  }
`;
export const listChurches = /* GraphQL */ `
  query ListChurches(
    $filter: ModelChurchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChurches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        address
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        churchLocationId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getForm = /* GraphQL */ `
  query GetForm($id: ID!) {
    getForm(id: $id) {
      etareaGroup
      ministry
      language
      modality
      format
      subformat
      mentorType
      range
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listForms = /* GraphQL */ `
  query ListForms(
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        etareaGroup
        ministry
        language
        modality
        format
        subformat
        mentorType
        range
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      country
      distrite
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        country
        distrite
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      comment
      response {
        items {
          id
          parentComment {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          comment
          etareaGroup
          author {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          createdAt
          updatedAt
          userSubCommentId
          commentResponseId
          __typename
        }
        nextToken
        __typename
      }
      etareaGroup
      author {
        id
        username
        keyPhoto
        fullName
        phone
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        mentorType
        range
        Groups {
          items {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          nextToken
          __typename
        }
        subComment {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        membersGroups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        auth
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userLocationId
        userChurchId
        __typename
      }
      createdAt
      updatedAt
      userCommentsId
      __typename
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        comment
        response {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        etareaGroup
        author {
          id
          username
          keyPhoto
          fullName
          phone
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          mentorType
          range
          Groups {
            nextToken
            __typename
          }
          comments {
            nextToken
            __typename
          }
          subComment {
            nextToken
            __typename
          }
          membersGroups {
            nextToken
            __typename
          }
          auth
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userLocationId
          userChurchId
          __typename
        }
        createdAt
        updatedAt
        userCommentsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubComment = /* GraphQL */ `
  query GetSubComment($id: ID!) {
    getSubComment(id: $id) {
      id
      parentComment {
        id
        comment
        response {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        etareaGroup
        author {
          id
          username
          keyPhoto
          fullName
          phone
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          mentorType
          range
          Groups {
            nextToken
            __typename
          }
          comments {
            nextToken
            __typename
          }
          subComment {
            nextToken
            __typename
          }
          membersGroups {
            nextToken
            __typename
          }
          auth
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userLocationId
          userChurchId
          __typename
        }
        createdAt
        updatedAt
        userCommentsId
        __typename
      }
      comment
      etareaGroup
      author {
        id
        username
        keyPhoto
        fullName
        phone
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        mentorType
        range
        Groups {
          items {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          nextToken
          __typename
        }
        subComment {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        membersGroups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        auth
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userLocationId
        userChurchId
        __typename
      }
      createdAt
      updatedAt
      userSubCommentId
      commentResponseId
      __typename
    }
  }
`;
export const listSubComments = /* GraphQL */ `
  query ListSubComments(
    $filter: ModelSubCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        parentComment {
          id
          comment
          response {
            nextToken
            __typename
          }
          etareaGroup
          author {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          createdAt
          updatedAt
          userCommentsId
          __typename
        }
        comment
        etareaGroup
        author {
          id
          username
          keyPhoto
          fullName
          phone
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          mentorType
          range
          Groups {
            nextToken
            __typename
          }
          comments {
            nextToken
            __typename
          }
          subComment {
            nextToken
            __typename
          }
          membersGroups {
            nextToken
            __typename
          }
          auth
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userLocationId
          userChurchId
          __typename
        }
        createdAt
        updatedAt
        userSubCommentId
        commentResponseId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSessionUser = /* GraphQL */ `
  query GetSessionUser($id: ID!) {
    getSessionUser(id: $id) {
      id
      status
      user {
        id
        username
        keyPhoto
        fullName
        phone
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        mentorType
        range
        Groups {
          items {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          nextToken
          __typename
        }
        subComment {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        membersGroups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        auth
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userLocationId
        userChurchId
        __typename
      }
      session {
        id
        date
        sessionNum
        group {
          id
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          sections {
            nextToken
            __typename
          }
          etareaGroup
          country
          distrite
          groupName
          description
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          initialDate
          finishDate
          members {
            nextToken
            __typename
          }
          ministry
          language
          modality
          progress
          format
          subformat
          sessions {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userGroupsId
          groupChurchId
          __typename
        }
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        groupSessionsId
        __typename
      }
      createdAt
      updatedAt
      sessionsSessionUsersId
      userSessionUsersId
      __typename
    }
  }
`;
export const listSessionUsers = /* GraphQL */ `
  query ListSessionUsers(
    $filter: ModelSessionUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessionUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        user {
          id
          username
          keyPhoto
          fullName
          phone
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          mentorType
          range
          Groups {
            nextToken
            __typename
          }
          comments {
            nextToken
            __typename
          }
          subComment {
            nextToken
            __typename
          }
          membersGroups {
            nextToken
            __typename
          }
          auth
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userLocationId
          userChurchId
          __typename
        }
        session {
          id
          date
          sessionNum
          group {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          groupSessionsId
          __typename
        }
        createdAt
        updatedAt
        sessionsSessionUsersId
        userSessionUsersId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGroupsUsers = /* GraphQL */ `
  query GetGroupsUsers($id: ID!) {
    getGroupsUsers(id: $id) {
      id
      userID
      groupID
      user {
        id
        username
        keyPhoto
        fullName
        phone
        location {
          id
          country
          distrite
          createdAt
          updatedAt
          __typename
        }
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        mentorType
        range
        Groups {
          items {
            id
            etareaGroup
            country
            distrite
            groupName
            description
            initialDate
            finishDate
            ministry
            language
            modality
            progress
            format
            subformat
            createdAt
            updatedAt
            userGroupsId
            groupChurchId
            __typename
          }
          nextToken
          __typename
        }
        comments {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userCommentsId
            __typename
          }
          nextToken
          __typename
        }
        subComment {
          items {
            id
            comment
            etareaGroup
            createdAt
            updatedAt
            userSubCommentId
            commentResponseId
            __typename
          }
          nextToken
          __typename
        }
        membersGroups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        auth
        sessionUsers {
          items {
            id
            status
            createdAt
            updatedAt
            sessionsSessionUsersId
            userSessionUsersId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userLocationId
        userChurchId
        __typename
      }
      group {
        id
        user {
          id
          username
          keyPhoto
          fullName
          phone
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          mentorType
          range
          Groups {
            nextToken
            __typename
          }
          comments {
            nextToken
            __typename
          }
          subComment {
            nextToken
            __typename
          }
          membersGroups {
            nextToken
            __typename
          }
          auth
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userLocationId
          userChurchId
          __typename
        }
        sections {
          items {
            id
            activeMembers
            initialDate
            state
            createdAt
            updatedAt
            groupSectionsId
            __typename
          }
          nextToken
          __typename
        }
        etareaGroup
        country
        distrite
        groupName
        description
        church {
          id
          name
          address
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          churchLocationId
          __typename
        }
        initialDate
        finishDate
        members {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ministry
        language
        modality
        progress
        format
        subformat
        sessions {
          items {
            id
            date
            sessionNum
            createdAt
            updatedAt
            groupSessionsId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userGroupsId
        groupChurchId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGroupsUsers = /* GraphQL */ `
  query ListGroupsUsers(
    $filter: ModelGroupsUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupsUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        groupID
        user {
          id
          username
          keyPhoto
          fullName
          phone
          location {
            id
            country
            distrite
            createdAt
            updatedAt
            __typename
          }
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          mentorType
          range
          Groups {
            nextToken
            __typename
          }
          comments {
            nextToken
            __typename
          }
          subComment {
            nextToken
            __typename
          }
          membersGroups {
            nextToken
            __typename
          }
          auth
          sessionUsers {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userLocationId
          userChurchId
          __typename
        }
        group {
          id
          user {
            id
            username
            keyPhoto
            fullName
            phone
            mentorType
            range
            auth
            createdAt
            updatedAt
            userLocationId
            userChurchId
            __typename
          }
          sections {
            nextToken
            __typename
          }
          etareaGroup
          country
          distrite
          groupName
          description
          church {
            id
            name
            address
            createdAt
            updatedAt
            churchLocationId
            __typename
          }
          initialDate
          finishDate
          members {
            nextToken
            __typename
          }
          ministry
          language
          modality
          progress
          format
          subformat
          sessions {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          userGroupsId
          groupChurchId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
