/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://oobvezjhajcslca22ljewvs2sm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-yxb47chgivf6fa5la3yv6khpyi",
    "aws_cognito_identity_pool_id": "us-east-1:c5e3f1e6-cc05-46b0-a6ba-91f822ded5f1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_8P6cJi8Gh",
    "aws_user_pools_web_client_id": "2htcu7u2l5g9pl8b9t4eqfqegb",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "institutoiwinorg4461ec5e325c4376bba00e70b2b72f4162603-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
